import React from 'react'

import { FooterContainer } from './styled'

import LogoBranco from './../assets/logo-branco.svg'
import LogoFleury from './../assets/logo-fleury-branco.svg'

import { Grid, Row, Col } from 'atomic'

export default props => {
  return (
    <FooterContainer>
      <Grid>
        <Row className="row-logos">
          <Col xs={12} lg={4} className="logo">
            <img src={LogoBranco} alt="Fleury Fertilidade" />
          </Col>
          <Col xs={12} lg={4} lgOffset={4} className="titulo">
            <p>{props.titulo}</p>
          </Col>
        </Row>

        <Row className="row-links">
          <Col xs={3} lg={3} className="logo-grupo">
            <img src={LogoFleury} alt="Grupo Fleury" />
          </Col>
          <Col xs={9} lg={9} className="col-links">
            <Row className="links">
              {props.links &&
                props.links.map((link, index) => (
                  <Col xs={4} lg={2} key={`link-footer-${index}`} className="ctn-link">
                    <h4>
                      <a
                        className="link"
                        href={link.link}
                        {...(link.external ? { target: '_blank' } : {})}
                      >
                        {link.texto}
                      </a>
                    </h4>
                  </Col>
                ))}
            </Row>
          </Col>
        </Row>

        <Row>
          <Col xs={12} lg={12} className="copyright">
            <div dangerouslySetInnerHTML={{ __html: props.copyright }} />
          </Col>
        </Row>
      </Grid>
    </FooterContainer>
  )
}
