import React, { useEffect, useState } from 'react'

import { HeaderContainer } from './styled'

import Logo from './../assets/logo.svg'

export default props => {
  const scrollToShow = 530
  const [showHeader, setShowHeader] = useState(false)

  const checkScroll = () => {
    setShowHeader(window.scrollY > scrollToShow)
  }

  useEffect(() => {
    checkScroll()

    window.addEventListener('scroll', checkScroll)

    return () => {
      window.removeEventListener('scroll', checkScroll)
    }
  })

  return (
    <>
      {showHeader && (
        <HeaderContainer>
          <h1>
            <span>Fleury Fertilidade </span>
            <img src={Logo} className="logo" alt="Fleury Fertilidade" />
          </h1>
        </HeaderContainer>
      )}
    </>
  )
}
