import styled from 'styled-components'

export const ServicesContainer = styled.div`
  width: 100%;
  padding-bottom: 50px;
  display: flex;
  justify-content: center;
  position: relative;

  .services-list {
    margin-top: 60px;
    align-items: center;

    .service-item {
      display: flex;
      flex-direction: column;
      align-items: center;

      .icon {
        margin-bottom: 40px;
      }

      .ctn-textos {
        display: flex;
        justify-content: flex-start;
        height: 60px;
        text-align: center;
        flex-direction: column;

        .title {
          font-weight: 600;
          font-size: 20px;
          line-height: 28px;
          margin: 0;
          margin-bottom: 10px;
          padding: 0;
        }

        p {
          text-align: center;
          color: #77787b;

          &.subtitle {
            font-size: 14px;
            line-height: 20px;
            margin-top: 0;
            text-align: center;
          }
        }
      }
    }
  }

  .efeito {
    position: absolute;
    right: -160px;
    top: -30px;
  }

  .carousel-root {
    margin-top: 40px;
    display: none;

    width: 100%;
    max-width: 400px;
    margin: auto;

    .item-carrousel {
      background: white;
      height: 420px;
      padding-left: 0.5em;
      padding-right: 0.5em;

      padding-bottom: 100px;

      margin-left: 20px;
      margin-right: 20px;
      border-radius: 20px;
      .icon {
        width: auto;
        margin-top: 40px;
        margin-bottom: 40px;
      }

      .title {
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        /* identical to box height, or 140% */

        text-align: center;
      }

      .subtitle {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        /* or 171% */

        text-align: center;
      }
    }

    .carousel-status {
      display: none;
    }

    .slide {
      background: none;

      &.selected {
        // background: white;
        .item-carrousel {
          // margin-left: 60px;
        }
      }
    }

    .dot {
      background: #b4b5b4 !important;
      opacity: 1 !important;
      margin: 0 3px !important;
      box-shadow: none !important;
      bottom: 20px;
      &.selected {
        background: #008471 !important;
      }
    }

    .control-arrow {
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      &:hover,
      &:active {
        background: none !important;
      }
    }

    .control-prev {
      opacity: 1 !important;
      left: -10px !important;
      &:before {
        border-right: 8px solid #008471 !important;
      }
    }

    .control-next {
      opacity: 1 !important;
      right: -10px !important;
      &:before {
        border-left: 8px solid #008471 !important;
      }
    }

    .thumbs-wrapper {
      display: none;
    }
  }

  @media (max-width: 1200px) {
    padding-top: 50px;
    .services-list {
      display: none;
    }

    .carousel-root {
      display: block;
    }

    .efeito,
    .efeito2 {
      display: none;
    }
  }
`
