import styled from 'styled-components'
import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  body {
   background: #878989;
  }
`

export const FertilidadeContainer = styled.div`
  h1,
  h2,
  h3,
  h4.h5,
  p,
  span,
  a {
    color: ##77797b;
  }

  width: 100%;
  overflow: hidden;

  background: #ebebeb;
`

export const WrapperEfeito = styled.div`
  width: 100%;
  position: relative;

  height: 120px;
  .efeito {
    position: absolute;
    right: 0;
    top: -30px;
  }

  @media (max-width: 1200px) {
    display: none;
  }
`
