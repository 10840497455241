import styled from 'styled-components'

export const ContactContainer = styled.div`
  position: relative;

  width: 100%;
  padding-bottom: 120px;
  padding-top: 20px;

  .wrapper-contact {
    display: flex;
    justify-content: center;
  }

  .efeito {
    position: absolute;
    top: 180px;
    left: -210px;
  }

  .contact-texts {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .hat {
      color: #008471;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 16px;
    }

    .title {
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 32px;

      color: #464646;
    }

    .subtitle {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;

      text-align: center;

      color: #373a3a;
      margin-bottom: 40px;
    }

    .button-title {
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 20px;

      color: #373f41;
      margin-bottom: 25px;
    }

    .button-whats {
      display: flex;
      position: relative;
      padding: 12px 24px;
      align-items: center;

      justify-content: center;

      width: 214px;
      height: 48px;

      background: #008471;
      border-radius: 24px;

      color: white;

      font-family: 'ASAP', Verdana;
      font-weight: 600;
      font-size: 16px;
      line-height: 16px;

      margin-bottom: 40px;

      img {
        // position: relative;
        // top: 5px;
        margin-right: 10px;
      }
    }
  }

  @media (max-width: 1200px) {
    .efeito {
      display: none;
    }
  }
`

export const Title = styled.h2`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;

  color: #373f41;

  text-transform: none;

  text-align: center;
`

export const Form = styled.form`
  margin-top: 40px;
  background: white;
  border-radius: 20px;
  padding: 64px;
  position: relative;
  height: 660px;

  .label {
    font-family: 'ASAP', Verdana;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #464646;
    margin-bottom: 10px;
  }

  input[type='submit'] {
    background: #008471;
    border-radius: 24px;
    width: 100%;
    height: 50px;
    font-style: normal;
    font-size: 16px;
    line-height: 16px;
    color: #f7f6f4;
    outline: none;
    border: none;
    cursor:pointer;
    font-family: 'ASAP', Verdana;
    font-weight:600;
  }

  .fields {
    margin-bottom: 60px;
  }

  .message {
    position: absolute;
    bottom: 80px;
    text-align: center;
    width: 100%;
    left: 0;

    p {
      color:#008471;
    }

    &.error {
      bottom:130px;
      p {
        color: red;
      }
    }
  }

  .loading {
    display: flex;
    justify-content: center;
   
    img {
      position:relative;
      left.-10px;
      top:-10px;
      width: 60px;
    }
  }

  @media (max-width: 1200px) {
    height: 800px;

   
    .inputContainer {
      margin-bottom: 24px;
    }

    .fields {
      margin-bottom: 0px;

      .col-fields {
        margin-bottom: 20px;
      }

      &.textarea {
        margin-top: 40px;
      }
    }
    
    input[type='submit'] {

      margin-top:60px;
    }


    .loading {
      img {    
        top:20px;
      }
    }
    .message {
  
      bottom: 80px;
      left:8px;
   
      &.error {
        bottom:120px;
      
      }
    }
  }


  @media (max-width: 480px) {
    padding:40px 24px;
  }

`
