import React, { useEffect, useRef } from 'react'

import { RadioContainer } from './styled-radio'

const Input = props => {
  const input = useRef()

  useEffect(() => {
    input.current.checked = props.checked
  }, [props.checked])

  const onChange = e => {
    props.onChange(e.target.value)
  }

  return (
    <RadioContainer className="inputContainer">
      <label>
        <div className="ctn-radio-input">
          <input
            type="radio"
            name={props.name}
            value={props.label}
            ref={input}
            onChange={onChange}
          />
          <div className="fake-check" />
        </div>

        {props.label}
      </label>
    </RadioContainer>
  )
}

Input.defaultProps = {
  onChange: () => {},
  required: false,
  name: '',
  checked: false
}

export default Input
