import styled from 'styled-components'

export const FooterContainer = styled.div`
  width: 100%;
  height: 370px;

  background: #878989;

  display: flex;
  overflow: hidden;

  .row-logos {
    border-bottom: 1px solid #b9b9b9;

    .titulo,
    .logo {
      height: 200px;
      display: flex;
      align-items: center;
    }

    .titulo {
      justify-content: flex-end;
      p {
        color: white;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 20px;
      }
    }
  }

  .row-links {
    height: 80px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #b9b9b9;

    .logo-grupo,
    .ctn-link {
      display: flex;
      justify-content: center;

      h4 {
        margin: 0;
        padding: 0;
      }
    }

    .link {
      font-family: 'ASAP', Verdana;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 14px;

      color: #f7f6f4;
    }
  }

  .copyright {
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      font-family: 'ASAP', Verdana;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 14px;
      color: white;
      text-align: center;
    }

    a {
      color: white;
    }
  }

  @media (max-width: 1200px) {
    height: 440px;

    .row-logos {
      margin-bottom: 40px;
      .titulo,
      .logo {
        height: 100px;

        img {
          width: 100%;
          max-width: 305px;
        }
      }

      .titulo {
        justify-content: flex-start;
      }
    }

    .row-links {
      height: 120px;
      align-items: flex-start;
      padding-bottom: 20px;

      .links {
        margin-top: 20px;
      }

      .link {
        font-weight: 600;
        height: 40px;
        display: flex;
        align-itens: center;
      }
    }
  }

  @media (max-width: 620px) {
    height: 650px;

    .row-links {
      height: 250px;
      padding-bottom: 0;

      .logo-grupo {
        width: 100%;
        max-width: 100%;
      }

      .col-links {
        width: 100%;
        max-width: 100%;
        flex-basis: 100%;

        .ctn-link {
          width: 50%;
          max-width: 50%;
          flex-basis: 50%;

          justify-content: flex-start;
        }
      }
    }

    .copyright {
      p {
        margin-top: 60px;
        line-height: 21px;
      }
    }
  }
`
