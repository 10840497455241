import styled from 'styled-components'

export const PresentationContainer = styled.div`
  width: 100%;
  padding-bottom: 50px;
`

export const ImageContainer = styled.div`
  position: relative;
  background-image: url(${props => props.imagem});
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;

  height: 530px;
  display: flex;
  align-items: center;

  .logo {
    width: 100%;
    max-width: 548px;
    margin-bottom: 40px;
  }

  .efeito {
    position: absolute;
    left: 20px;
    top: 20px;
  }

  .arrow-down {
    display: none;
  }

  @media (max-width: 1200px) {
    height: 768px;
    // padding-bottom: 200px;

    .logo {
      margin-top: 160px;
    }

    background-image: url(${props => props.imagem_tablet});

    .efeito {
      left: 10px;
      top: 10px;
    }

    .arrow-down {
      display: block;

      position: absolute;
      bottom: 80px;

      left: 50vw;
      margin-left: -30px;
    }
  }

  @media (max-width: 480px) {
    height: 100vh;

    .logo {
      // margin-top: 40vh;
    }

    .arrow-down {
      bottom: 5vh;
    }

    background-image: url(${props => props.imagem_mobile});
  }
`

export const DivEfeito = styled.div`
  background-image: url(${props => props.imagem});
  width: 744px;
  height: 467px;
  background-repeat: no-repeat;

  @media (max-width: 1200px) {
    background-image: url(${props => props.imagem_tablet});

    width: 896px;
    height: 467px;
  }

  @media (max-width: 480px) {
    background-image: url(${props => props.imagem_mobile});

    width: 280px;
    height: 300px;
  }
`
