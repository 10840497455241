import React, { useState, useRef } from 'react'

import { Row, Col } from 'atomic'

import { ContactContainer, Title, Form } from './styled'

import Efeito from './../assets/efeito-4.svg'
import Spinner from './../assets/spinner.svg'

import Input from 'site/src/components/landing-design-system/input'

import RadioInput from 'site/src/components/landing-design-system/input/radio'

import TextArea from 'site/src/components/landing-design-system/input/textarea'

import IconWhatsApp from './../assets/whatsapp.svg'

import {
  buildSendEmailRequest,
  sendEmailUrl
} from 'site/src/data/http/request-builder/send-email.request'

import { HttpPostContainer } from 'site/src/components/legacy/obj.http-container/http-post-container.component'

export default props => {
  const handleFormSuccess = () => {}
  const form = useRef()
  const [message, setMessage] = useState({})
  const [loading, setLoading] = useState(false)

  return (
    <HttpPostContainer url={sendEmailUrl} onSuccess={handleFormSuccess}>
      {(post, state) => {
        const onSubmit = async e => {
          e.preventDefault()

          const formData = new FormData(e.target)
          let values = {}

          for (let pair of formData.entries()) {
            values[pair[0]] = pair[1]
          }

          const email = props.email_destinatario

          const emailSubject = `Fleury Fertilidade - Contato - ${values.nome}`
          let emailContent = `<p><strong>Uma nova mensagem enviado por ${values.nome}</strong></p><br/>`
          emailContent += `<p>Email: ${values.email}</p>`
          emailContent += `<p>Telefone: ${values.telefone}</p>`
          emailContent += `<p>Função: ${values.funcao}</p>`
          emailContent += `<br/><p>Mensagem:<br/>${values.mensagem}</p>`

          let request = buildSendEmailRequest(email, emailSubject, emailContent)

          setLoading(true)

          setMessage({})
          try {
            await post(request)

            setMessage({
              error: false,
              text: 'Sua mensagem foi enviada com sucesso. Em breve entraremos em contato.'
            })

            //form.current.reset()

            //  console.log(form.current)
          } catch (e) {
            setMessage({
              error: true,
              text: 'Ocorreu uma falha ao enviar sua mensagem. Por favor tente de novo.'
            })
          }

          setLoading(false)
        }

        return (
          <ContactContainer>
            <Row className="wrapper-contact">
              <Col xs={12} lg={5} className="contact-texts">
                <p className="hat">{props.hat}</p>
                <p className="title">{props.titulo}</p>
                <p className="subtitle">{props.subtitulo}</p>
                <p className="button-title">{props.titulo_whats}</p>
                <a
                  className="button-whats"
                  href={`https://wa.me/${props.whats_numero}?text=${encodeURI(
                    props.texto_whatsapp
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={IconWhatsApp} alt="icone whatsapp" />
                  {props.texto_botao}
                </a>
              </Col>
            </Row>
            <Row className="wrapper-contact">
              <Col xs={12} lg={8}>
                <Title>{props.titulo_email}</Title>
              </Col>

              <Col clasName="form" xs={12} lg={8}>
                <Form onSubmit={onSubmit} ref={form}>
                  <Row className="fields">
                    <Col xs={12} lg={12} className="col-fields">
                      <Input label="Nome completo" minLength={3} required name="nome" />
                    </Col>
                  </Row>

                  <Row className="fields">
                    <Col xs={12} lg={6} className="col-fields">
                      <Input label="Email" type="email" required name="email" />
                    </Col>
                    <Col xs={12} lg={5} lgOffset={1} xsOffset={0} className="col-fields">
                      <Input
                        label="Telefone"
                        type="tel"
                        mask="(99) 99999-9999"
                        required
                        minLength={13}
                        name="telefone"
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={6} lg={4}>
                      <p className="label">Você é</p>
                    </Col>
                  </Row>

                  <Row className="fields">
                    <Col xs={12} lg={2}>
                      <RadioInput name="funcao" label="Cliente" checked />
                    </Col>
                    <Col xs={12} lg={4}>
                      <RadioInput name="funcao" label="Profissional de Saúde" />
                    </Col>
                    <Col xs={12} lg={2}>
                      <RadioInput name="funcao" label="Outro" />
                    </Col>
                  </Row>

                  <Row className="fields textarea">
                    <Col xs={12} lg={12}>
                      <TextArea label="Digite sua mensagem" required name="mensagem" />
                    </Col>
                  </Row>

                  {message.text && (
                    <Row className={`message ${message.error ? 'error' : ''}`}>
                      <Col xs={12} lg={12}>
                        <p>{message.text}</p>
                      </Col>
                    </Row>
                  )}

                  <Row>
                    <Col xs={12} lg={12}>
                      {loading ? (
                        <div className="loading">
                          <img alt="spiner" src={Spinner} />
                        </div>
                      ) : (
                        <>
                          {(message.error || !message.text) && (
                            <input type="submit" value="Cadastrar" />
                          )}
                        </>
                      )}
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>

            <img src={Efeito} className="efeito" alt="efeito" />
          </ContactContainer>
        )
      }}
    </HttpPostContainer>
  )
}
