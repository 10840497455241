import React from 'react'

import { SEO } from 'site/src/components/legacy/mol.seo/seo.component'
import { Grid } from 'atomic'

import { graphql } from 'gatsby'
import { FertilidadeContainer, WrapperEfeito, GlobalStyle } from './styled'

import Header from './header'
import Banner from './banner'
import Presentation from './presentation'
import Services from './services'
import Contact from './contact'
import Footer from './footer'
import Efeito from './assets/efeito-3.svg'

export default props => {
  const { metadata } = props.data.cosmicjsLandingPages

  return (
    <>
      <GlobalStyle />
      <Header />
      <SEO
        socialMedia={{
          canonicalUrl: `http://fleury.com.br/outros-servicos/fleury-fertilidade`,
          title: metadata.apresentacao.titulo,
          image: metadata.banner.imagem.url,
          imageAlt: metadata.apresentacao.titulo,
          description: metadata.apresentacao.descricao
        }}
      />
      <Grid>
        {/* <TitleWithBreadcrumbRow
          addtionalDictionary={{ '/fleury-fertilidade': 'Fleury Fertilidade' }}
        /> */}
      </Grid>

      <FertilidadeContainer>
        <Banner {...metadata.banner} />
        <Grid>
          <Presentation {...metadata.apresentacao} />
          <Services {...metadata.servicos} />
        </Grid>

        <WrapperEfeito>
          <img src={Efeito} className="efeito" alt="efeito" />
        </WrapperEfeito>

        <Grid>
          <Contact {...metadata.formulario} />
        </Grid>
      </FertilidadeContainer>

      <Footer {...metadata.footer} />
    </>
  )
}

export const query = graphql`
  query Fertilidade {
    cosmicjsLandingPages(slug: { eq: "land-fertilidade" }) {
      id
      metadata {
        banner {
          titulo
          imagem {
            url
          }
          imagem_tablet {
            url
          }
          imagem_mobile {
            url
          }
          logo {
            url
          }
        }
        apresentacao {
          hat
          titulo
          descricao
        }
        servicos {
          items {
            icone {
              url
            }
            titulo
            subtitulo
          }
        }
        formulario {
          hat
          titulo
          subtitulo
          titulo_whats
          texto_botao
          whats_numero
          texto_whatsapp
          titulo_email
          email_destinatario
        }
        footer {
          links {
            texto
            link
            external
          }
          titulo
          copyright
        }
      }
    }
  }
`
