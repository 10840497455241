import React, { useState } from 'react'

import { InputContainer } from './styled'

const Input = props => {
  const [value, setValue] = useState('')

  const onChange = e => {
    let value = e.target.value

    setValue(value)
    props.onChange(value)
  }

  return (
    <InputContainer className="inputContainer">
      <textarea
        type={props.type}
        onChange={onChange}
        required={props.required}
        minLength={props.minLength}
        className={`${value ? 'filled' : ''}`}
        name={props.name}
      />

      {props.label && <label className={`${value ? 'animated' : ''}`}>{props.label}</label>}
    </InputContainer>
  )
}

Input.defaultProps = {
  type: 'text',
  label: null,
  onChange: () => {},
  mask: null,
  required: false,
  minLength: 0,
  name: ''
}

export default Input
