import styled from 'styled-components'

import RadioOff from './assets/radio-off.svg'
import RadioOn from './assets/radio-on.svg'

export const RadioContainer = styled.div`
  label {
    font-family: 'ASAP', Verdana;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    color: #707070;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;

    .ctn-radio-input {
      width: 25px;
      height: 25px;
      display: inline-block;
      position: relative;
      margin-right: 5px;

      .fake-check {
        position: absolute;
        width: 25px;
        height: 25px;
        display: inline-block;
        background: url(${RadioOff});
        background-repeat: no-repeat;
        left: 0;
        top: 0;
      }

      input {
        pointer-events: none;
        opacity: 0;

        &:checked + .fake-check {
          background: url(${RadioOn});
          background-repeat: no-repeat;
        }
      }
    }
  }
`
