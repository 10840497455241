import React from 'react'

import { Row, Col } from 'atomic'

import { PresentationContainer, Hat, Title, Description } from './styled'

import Efeito from './../assets/efeito-1.svg'

export default props => {
  return (
    <PresentationContainer>
      <Row className="wrapper-presentation">
        <Col xs={12} lg={4}>
          <Hat>{props.hat}</Hat>
          <Title>{props.titulo}</Title>
          <Description className="texto">{props.descricao} </Description>
        </Col>
      </Row>

      <img src={Efeito} className="efeito" alt="efeito" />
    </PresentationContainer>
  )
}
