import React from 'react'

import { Row, Col } from 'atomic'

import { ServicesContainer } from './styled'

import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'

import Efeito from './../assets/efeito-2.svg'

export default props => {
  // const carrouselArray = chunkArray(props.items, 3)

  return (
    <>
      <ServicesContainer>
        <Row>
          <Col xs={12} lg={12}>
            <Row className="services-list">
              {props.items.map((servico, index) => (
                <Col className="service-item" xs={12} lg={3} key={`link-services-${index}`}>
                  <img src={servico.icone.url} className="icon" alt={servico.titulo} />
                  <div className="ctn-textos">
                    <h3 className="title">{servico.titulo}</h3>
                    <p className="subtitle">{servico.subtitulo}</p>
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>

        <Carousel emulateTouch infiniteLoop>
          {props.items.map((item, index) => (
            <div className="item-carrousel" key={`carrousel-item-${index}`}>
              <img src={item.icone.url} className="icon" alt={item.titulo} />
              <p className="title">{item.titulo}</p>
              <p className="subtitle">{item.subtitulo}</p>
            </div>
          ))}
        </Carousel>
        <img src={Efeito} className="efeito" alt="efeito" />
      </ServicesContainer>
    </>
  )
}
