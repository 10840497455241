import React from 'react'

import { Row, Col, Grid } from 'atomic'

import { PresentationContainer, ImageContainer, DivEfeito } from './styled'

import Title from 'site/src/components/landing-design-system/title'

import Efeito from './../assets/efeito-banner.svg'
import EfeitoTablet from './../assets/efeito-banner-tablet.svg'
import EfeitoMobile from './../assets/efeito-banner-mobile.svg'
import ArrowDown from './../assets/arrow-down.svg'

import scrollTo from 'site/src/utils/scrollTo'

export default props => {
  return (
    <PresentationContainer>
      <ImageContainer
        imagem={props.imagem.url}
        imagem_mobile={props.imagem_mobile.url}
        imagem_tablet={props.imagem_tablet.url}
      >
        <DivEfeito
          imagem={Efeito}
          imagem_tablet={EfeitoTablet}
          imagem_mobile={EfeitoMobile}
          className="efeito"
          alt="efeito"
        />
        <Grid className="wrapper-banner">
          <Row>
            <Col xs={12} lg={6}>
              <img src={props.logo.url} className="logo" alt="Fleury Fertilidade" />
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={5}>
              <Title color="#FFFFFF" hideLine fontSize="16px" lineHeight="24px" fontWeight="normal">
                {props.titulo}
              </Title>
            </Col>
          </Row>
        </Grid>

        <img
          className="arrow-down"
          alt="arrow-down"
          src={ArrowDown}
          onClick={e => {
            if (window.innerWidth <= 1200 && window.innerWidth > 480) {
              scrollTo(document.documentElement, 700, 400)
            } else {
              const top = window.innerHeight
              scrollTo(document.documentElement, top + 1, 400)
            }
          }}
        />
      </ImageContainer>
    </PresentationContainer>
  )
}
