import styled from 'styled-components'

export const PresentationContainer = styled.div`
  position: relative;

  width: 100%;
  padding-bottom: 50px;
  padding-top: 50px;

  .wrapper-presentation {
    display: flex;
    justify-content: center;
  }

  .efeito {
    position: absolute;
    top: 0px;
    left: -115px;
  }

  @media (max-width: 1200px) {
    .efeito {
      display: none;
    }
  }
`

export const Hat = styled.p`
  font-family: 'ASAP', Verdana;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #008471;
  text-align: center;
`

export const Title = styled.h2`
  font-family: 'ASAP', Verdana;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  color: #464646;
  text-align: center;

  margin-top: 20px;
  margin-bottom: 20px;

  padding: 0;
`

export const Description = styled.p`
  font-family: 'ASAP', Verdana;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #77787b;
  text-align: center;
`
